import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { logoutUser, setHeader } from 'state/logged-user/actions';
import { isItLogged, getLoginData, getHeaderStatus } from 'state/logged-user/selectors';
import { fetchCountries } from 'state/address/actions';
import { getCountries } from 'state/address/selectors';
import * as uiActionCreators from 'state/modal-drawer/actions';
import { reloadComponent, showAlertModal, showModal } from 'state/modal-drawer/selectors';
import { fetchNotifList, fetchMoreNotifList, readNotif, unreadNotif, clearNotification } from 'state/notification/actions';
import { getNotifData, getUnreadNotifData, getNotifListData } from 'state/notification/selectors';
import { isItActionShowingLoad, getLoadType } from 'state/loading/selectors';
import { setLoadType } from 'state/loading/actions';
import { fetchCalendarEventsNotif, clearCalendarEventsNotif, apiRefresh } from 'state/settings/actions';
import { getEventsNotif } from 'state/settings/selectors';
import PropTypes from 'prop-types';
import Loadable from 'react-loadable';
import withTheme from 'ui/withTheme';

const Loading = () => <div>&nbsp;</div>;

const Header = Loadable({
  loader: () => import(/* webpackChunkName: "header" */'ui/components/common/Header'),
  loading: Loading,
});

const Navigation = Loadable({
  loader: () => import(/* webpackChunkName: "navigation" */'ui/components/common/Navigation'),
  loading: Loading,
});

const AlertModal = Loadable({
  loader: () => import(/* webpackChunkName: "alert-modal" */'ui/components/modal/AlertModal'),
  loading: Loading,
});

const WAIT_INTERVAL = (1000 * 60) * 20; // 20 minutes interval

class Layout extends Component {
  componentWillMount() {
    this.rtimer = null;
  }

  componentDidMount() {
    const { location, logged } = this.props;
    localStorage.setItem('redirectPath', location.pathname);
    if (!logged) {
      if (location.pathname !== '/admin-login' && location.pathname !== '/reset' && location.pathname !== '/reset-link') {
        this.props.history.push('/login'); // eslint-disable-line
      }
    }
    if (logged) {
      if (location.pathname === '/login' || location.pathname === '/admin-login' || location.pathname === '/reset' || location.pathname === '/reset-link') {
        this.props.history.push('/'); // eslint-disable-line
      }
      const { profile } = this.props;
      if (!profile.customer.is_data_sync) {
        this.props.history.push('/welcome'); // eslint-disable-line
      }
      if (profile.customer.is_data_sync) {
        if (location.pathname === '/welcome') {
          this.props.history.push('/'); // eslint-disable-line
        }
      }
      const { countries, countryList, calendarEventsNotif } = this.props;
      if (countries === null) {
        countryList();
      }
      calendarEventsNotif();
      this.rtimer = setInterval(() => { this.refreshToken(); }, WAIT_INTERVAL);
    }
  }

  refreshToken = () => {
    const { refreshApi } = this.props;
    refreshApi();
  }

  render() {
    const { location, logged, ...otherDatas } = this.props;
    const { actions, alertModal, ...others } = this.props;
    if (location.pathname === '/ips-operations') {
      return (
        this.props.children
      );
    }
    return (
      <div className="App">
        { logged && location.pathname !== '/login' && location.pathname !== '/admin-login' && location.pathname !== '/reset' && location.pathname !== '/reset-link' && location.pathname !== '/welcome' ?
          <Fragment>
            <Header {...otherDatas} />
            <Navigation {...others} />
          </Fragment>
        : null }
        {
          this.props.children
        }
        {/* {React.cloneElement(this.props.children, { loggedIn: false })} */}
        <AlertModal
          modalKey="alert"
          modalState={alertModal}
          closeAction={actions.ui.closeAlertModal}
          {...others}
        />
      </div>
    );
  }
}

Layout.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  logged: PropTypes.bool.isRequired,
  actions: PropTypes.instanceOf(Object).isRequired,
  countries: PropTypes.array, // eslint-disable-line
  countryList: PropTypes.func.isRequired,
  calendarEventsNotif: PropTypes.func.isRequired,
};

export const mapStateToProps = state => ({
  logged: isItLogged(state),
  profile: getLoginData(state),
  reload: reloadComponent(state),
  modal: showModal(state),
  alertModal: showAlertModal(state),
  countries: getCountries(state),
  headerStatus: getHeaderStatus(state),
  notifdata: getNotifData(state),
  unreadnotifdata: getUnreadNotifData(state),
  notiflistdata: getNotifListData(state),
  actionloading: isItActionShowingLoad(state),
  getloadtype: getLoadType(state),
  eventsnotif: getEventsNotif(state),
});

export const mapDispatchToProps = dispatch => ({
  handleLogout: () => {
    dispatch(logoutUser());
    setTimeout(() => {
      window.location.href = '/login';
    }, 1);
  },
  actions: { ui: bindActionCreators(uiActionCreators, dispatch) },
  setHeader: () => dispatch(setHeader('')),
  countryList: () => dispatch(fetchCountries()),
  notifList: (limit, page, all) => dispatch(fetchNotifList(limit, page, all)),
  moreNotifList: (limit, page, all) => dispatch(fetchMoreNotifList(limit, page, all)),
  notifRead: id => dispatch(readNotif(id)),
  notifUnRead: () => dispatch(unreadNotif()),
  clearNotif: () => dispatch(clearNotification()),
  calendarEventsNotif: () => dispatch(fetchCalendarEventsNotif()),
  resetCalendarEventsNotif: () => dispatch(clearCalendarEventsNotif()),
  refreshApi: () => dispatch(apiRefresh()),
  setloadtype: type => dispatch(setLoadType(type)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Layout)));
