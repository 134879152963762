import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route, withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import PageLoader from 'ui/components/loader/PageLoader';
import { ReactComponent as DashboardIcon } from 'resources/icons/dashboard.svg';
import { ReactComponent as SalesOrderIcon } from 'resources/icons/sales-order.svg';
import { ReactComponent as OrdersIcon } from 'resources/icons/orders.svg';
import { ReactComponent as ReceiptIcon } from 'resources/icons/receipt.svg';
import { ReactComponent as ProductsIcon } from 'resources/icons/products.svg';
import { ReactComponent as StockAlertIcon } from 'resources/icons/stock-alert.svg';
import { ReactComponent as ReportsIcon } from 'resources/icons/reports.svg';
import { ReactComponent as SuppportIcon } from 'resources/icons/support.svg';
import {
  PATH_DASHBOARD,
  PATH_VENDOR_ORDERS,
  PATH_SUPPLY_RECEIPTS,
  PATH_ORDERS,
  PATH_PRODUCTS,
  PATH_STOCK_ALERT_SETTINGS,
  PATH_REPORTS,
  PATH_SUPPORT,
} from 'app-init/routedir';
import style from './RouteStyle';

const PageDashboard = Loadable({
  loader: () => import(/* webpackChunkName: "dashboard" */'ui/pages/dashboard/DashboardContainer'),
  loading: PageLoader,
});

const PageOrders = Loadable({
  loader: () => import(/* webpackChunkName: "orders" */'ui/pages/orders/OrdersContainer'),
  loading: PageLoader,
});

const PagePendingOrders = Loadable({
  loader: () => import(/* webpackChunkName: "pending_orders" */'ui/pages/orders/PendingOrdersContainer'),
  loading: PageLoader,
});

const PageShippedOrders = Loadable({
  loader: () => import(/* webpackChunkName: "shipped_orders" */'ui/pages/orders/ShippedOrdersContainer'),
  loading: PageLoader,
});

const PageReturnedOrders = Loadable({
  loader: () => import(/* webpackChunkName: "returned_orders" */'ui/pages/orders/ReturnedOrdersContainer'),
  loading: PageLoader,
});

const PageReturnAuthorization = Loadable({
  loader: () => import(/* webpackChunkName: "returned_orders" */'ui/pages/orders/ReturnAuthorizationContainer'),
  loading: PageLoader,
});

const PageThirdParty = Loadable({
  loader: () => import(/* webpackChunkName: "third_party" */'ui/pages/orders/ThirdPartyContainer'),
  loading: PageLoader,
});

const PageProducts = Loadable({
  loader: () => import(/* webpackChunkName: "products" */'ui/pages/products/ProductsContainer'),
  loading: PageLoader,
});

const PageProductsVendorOrders = Loadable({
  loader: () => import(/* webpackChunkName: "products_vendor_orders" */'ui/pages/products/VendorOrdersContainer'),
  loading: PageLoader,
});

const Reports = Loadable({
  loader: () => import(/* webpackChunkName: "products_vendor_orders" */'ui/pages/products/supplyreceipts/SupplyReceiptsContainer'),
  loading: PageLoader,
});

const PageTopSellingProducts = Loadable({
  loader: () => import(/* webpackChunkName: "top_selling_products" */'ui/pages/products/ProductsContainer'),
  loading: PageLoader,
});

const PageProductsLowInStocks = Loadable({
  loader: () => import(/* webpackChunkName: "products_low_in_stock" */'ui/pages/products/ProductsContainer'),
  loading: PageLoader,
});

const PageProductsOutOfStocks = Loadable({
  loader: () => import(/* webpackChunkName: "products_out_of_stocks" */'ui/pages/products/ProductsContainer'),
  loading: PageLoader,
});

const PageStockAlertSettings = Loadable({
  loader: () => import(/* webpackChunkName: "stock_alert_settings" */'ui/pages/products/StockAlertContainer'),
  loading: PageLoader,
});

const PageReports = Loadable({
  loader: () => import(/* webpackChunkName: "reports" */'ui/pages/reports/orders/OrderReportContainer'),
  loading: PageLoader,
});

const PageSalesBySKU = Loadable({
  loader: () => import(/* webpackChunkName: "reports" */'ui/pages/reports/SalesBySKUContainer'),
  loading: PageLoader,
});

const PageInventoryReports = Loadable({
  loader: () => import(/* webpackChunkName: "reports" */'ui/pages/reports/inventory/InventoryReportsContainer'),
  loading: PageLoader,
});

const ReturnsReports = Loadable({
  loader: () => import(/* webpackChunkName: "reports" */'ui/pages/reports/returns/ReturnsContainer'),
  loading: PageLoader,
});

const PageSupplyReceiptsReports = Loadable({
  loader: () => import(/* webpackChunkName: "reports" */'ui/pages/reports/supplyreceipts/SupplyReceiptsContainer'),
  loading: PageLoader,
});

const PageSupport = Loadable({
  loader: () => import(/* webpackChunkName: "support" */'ui/pages/support/SupportContainer'),
  loading: PageLoader,
});

const PageBillingSupport = Loadable({
  loader: () => import(/* webpackChunkName: "support" */'ui/pages/support/BillingSupportContainer'),
  loading: PageLoader,
});

const PageTechSupport = Loadable({
  loader: () => import(/* webpackChunkName: "support" */'ui/pages/support/TechSupportContainer'),
  loading: PageLoader,
});

const PageSpecialProjects = Loadable({
  loader: () => import(/* webpackChunkName: "special_projects" */'ui/pages/support/SpecialProjectsContainer'),
  loading: PageLoader,
});

const PageHolidays = Loadable({
  loader: () => import(/* webpackChunkName: "holidays" */'ui/pages/holidays/HolidaysContainer'),
  loading: PageLoader,
});

const PageFaq = Loadable({
  loader: () => import(/* webpackChunkName: "faq" */'ui/pages/faq/FaqContainer'),
  loading: PageLoader,
});

const PageProfile = Loadable({
  loader: () => import(/* webpackChunkName: "profile" */'ui/pages/settings/ProfileContainer'),
  loading: PageLoader,
});

const PageManageRoles = Loadable({
  loader: () => import(/* webpackChunkName: "manage_roles" */'ui/pages/settings/ManageRolesContainer'),
  loading: PageLoader,
});

const PageAccountInfo = Loadable({
  loader: () => import(/* webpackChunkName: "staff" */'ui/pages/settings/AccountInfoContainer'),
  loading: PageLoader,
});

const PageAccountStaff = Loadable({
  loader: () => import(/* webpackChunkName: "staff" */'ui/pages/settings/AccountStaffContainer'),
  loading: PageLoader,
});

const PageNotificationSettings = Loadable({
  loader: () => import(/* webpackChunkName: "notification" */'ui/pages/settings/NotificationSettingsContainer'),
  loading: PageLoader,
});

const PageTimezone = Loadable({
  loader: () => import(/* webpackChunkName: "notification" */'ui/pages/settings/TimezoneContainer'),
  loading: PageLoader,
});

const PageLogin = Loadable({
  loader: () => import(/* webpackChunkName: "login" */'ui/components/auth/LoginContainer'),
  loading: PageLoader,
});

const AdminPageLogin = Loadable({
  loader: () => import(/* webpackChunkName: "login" */'ui/components/auth/AdminLoginContainer'),
  loading: PageLoader,
});

const PageResetPass = Loadable({
  loader: () => import(/* webpackChunkName: "reset_pass" */'ui/components/auth/ResetPass'),
  loading: PageLoader,
});

const PageResetLink = Loadable({
  loader: () => import(/* webpackChunkName: "reset_link" */'ui/components/auth/ResetLink'),
  loading: PageLoader,
});

const PageWelcomeLink = Loadable({
  loader: () => import(/* webpackChunkName: "welcome" */'ui/pages/dashboard/WelcomeContainer'),
  loading: PageLoader,
});

const PageOperations = Loadable({
  loader: () => import(/* webpackChunkName: "operations" */'ui/pages/operations/OperationsContainer'),
  loading: PageLoader,
});

export const menuItems = [
  { label: 'Dashboard', url: PATH_DASHBOARD, icon: <DashboardIcon /> },
  { label: 'Sales Orders', url: PATH_ORDERS, icon: <SalesOrderIcon /> },
  { label: 'Purchase Orders', url: PATH_VENDOR_ORDERS, icon: <OrdersIcon /> },
  { label: 'Supply Receipts', url: PATH_SUPPLY_RECEIPTS, icon: <ReceiptIcon /> },
  { label: 'Products', url: PATH_PRODUCTS, icon: <ProductsIcon /> },
  { label: 'Stock Alerts', url: PATH_STOCK_ALERT_SETTINGS, icon: <StockAlertIcon /> },
  { label: 'Reports', url: PATH_REPORTS, icon: <ReportsIcon /> },
  { label: 'Support', url: PATH_SUPPORT, icon: <SuppportIcon /> },
];

class Routes extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.onRouteChanged(this.props.location, prevProps.location);
    }
  }
  render() {
    const { classes, location } = this.props;
    let container = (location.pathname !== '/login' && location.pathname !== '/admin-login' && location.pathname !== '/reset') ? classes.container : '';
    container = location.pathname === '/ips-operations' ? '' : container;
    return (
      <div className={container}>
        <Switch>
          <Route exact path="/" component={PageDashboard} />
          <Route path="/orders" component={PageOrders} />
          <Route path="/pending-orders" component={PagePendingOrders} />
          <Route path="/shipped-orders" component={PageShippedOrders} />
          <Route path="/returned-orders" component={PageReturnedOrders} />
          <Route path="/return-authorization" component={PageReturnAuthorization} />
          <Route path="/third-party" component={PageThirdParty} />
          <Route path="/products" component={PageProducts} />
          <Route path="/products/:id" component={PageProducts} />
          <Route path="/products-vendor-orders" component={PageProductsVendorOrders} />
          <Route path="/supply-receipts" component={Reports} />
          <Route path="/top-selling-products" component={PageTopSellingProducts} />
          <Route path="/products-low-in-stocks" component={PageProductsLowInStocks} />
          <Route path="/products-out-of-stocks" component={PageProductsOutOfStocks} />
          <Route path="/stock-alert-settings" component={PageStockAlertSettings} />
          <Route path="/reports" component={PageReports} />
          <Route path="/inventory-reports" component={PageInventoryReports} />
          <Route path="/returns-reports" component={ReturnsReports} />
          <Route path="/supply-receipts-reports" component={PageSupplyReceiptsReports} />
          <Route path="/sales-by-sku" component={PageSalesBySKU} />
          <Route path="/support" component={PageSupport} />
          <Route path="/support/chat/:id" component={PageSupport} />
          <Route path="/billing-support" component={PageBillingSupport} />
          <Route path="/billing-support/chat/:id" component={PageBillingSupport} />
          <Route path="/tech-support" component={PageTechSupport} />
          <Route path="/tech-support/chat/:id" component={PageTechSupport} />
          <Route path="/special-projects" component={PageSpecialProjects} />
          <Route path="/holidays" component={PageHolidays} />
          <Route path="/faq" component={PageFaq} />
          <Route path="/profile" component={PageProfile} />
          <Route path="/manage-roles" component={PageManageRoles} />
          <Route path="/account-info" component={PageAccountInfo} />
          <Route path="/staff" component={PageAccountStaff} />
          <Route path="/notification" component={PageNotificationSettings} />
          <Route path="/timezone" component={PageTimezone} />
          <Route path="/login" component={PageLogin} />
          <Route path="/admin-login" component={AdminPageLogin} />
          <Route path="/ips-operations" component={PageOperations} />
          <Route path="/reset" component={PageResetPass} />
          <Route path="/reset-link" component={PageResetLink} />
          <Route path="/welcome" component={PageWelcomeLink} />
        </Switch>
      </div>
    );
  }
}

Routes.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  onRouteChanged: PropTypes.func.isRequired,
};

export default withRouter(withStyles(style)(Routes));
