import { getCountries, getUSZipCodeList, getUSState } from 'api/address';
import responseCheker from 'api/responseCheker';
import { openAlertModal } from 'state/modal-drawer/actions';
import { showActionLoading, hideActionLoading } from 'state/loading/actions';
import { SET_COUNTRIES, UNSET_COUNTRIES, SET_ZIP_CODE_LIST, SET_STATE_DATA, SET_ADDRESS_DATA } from './types';

export const setCountries = countries => ({
  type: SET_COUNTRIES,
  payload: {
    countries,
  },
});

export const setZipCodeList = zipcitystate => ({
  type: SET_ZIP_CODE_LIST,
  payload: {
    zipcitystate,
  },
});

export const setCountryState = countryState => ({
  type: SET_STATE_DATA,
  payload: {
    countryState,
  },
});

export const setAddressData = addressData => ({
  type: SET_ADDRESS_DATA,
  payload: {
    addressData,
  },
});

export const unsetCountries = () => ({
  type: UNSET_COUNTRIES,
  payload: {
    countries: null,
  },
});

export const successResponse = (response, type) => (dispatch) => {
  dispatch(responseCheker(response));
  if (response.data) {
    if (type === 'countries') {
      const c1 = response.data.data
        .filter(data => data.attributes.country_code === 'US'
            || data.attributes.country_code === 'CA'
            || data.attributes.country_code === 'MX')
        .map(country => (country))
        .reverse();
      const c2 = response.data.data
        .filter(data => data.attributes.country_code !== 'US'
            && data.attributes.country_code !== 'CA'
            && data.attributes.country_code !== 'MX')
        .map(country => (country));
      const customCountries = [...c1, ...c2];

      localStorage.setItem('countries', JSON.stringify(customCountries));
      dispatch(setCountries(customCountries));
    }
    if (type === 'zip') {
      dispatch(setZipCodeList(response.data.data));
    }
    if (type === 'state') {
      dispatch(setCountryState(response.data.data));
    }
  }
  dispatch(hideActionLoading());
};

export const errorResponse = err => (dispatch) => {
  const result = dispatch(responseCheker(err.response));
  if (result === 'error') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: err.response.data.errors[0].detail,
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  if (result === 'TypeError') {
    const modalKey = 'alert';
    const params = {
      type: 'error',
      title: '',
      description: 'Something went wrong. This has been reported',
    };
    dispatch(openAlertModal({ modalKey, params }));
  }
  dispatch(hideActionLoading());
};

export const fetchCountries = () => (dispatch) => {
  dispatch(showActionLoading());
  getCountries().then(
    response => dispatch(successResponse(response, 'countries')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchCountryState = () => (dispatch) => {
  dispatch(showActionLoading());
  getUSState().then(
    response => dispatch(successResponse(response, 'state')),
    err => dispatch(errorResponse(err)),
  );
};

export const fetchZipCodeList = (limit, zip, state, city) => (dispatch) => {
  dispatch(showActionLoading());
  getUSZipCodeList(limit, zip, state, city).then(
    response => dispatch(successResponse(response, 'zip')),
    err => dispatch(errorResponse(err)),
  );
};
